<template>
  <v-layout row wrap justify-space-between align->
    <v-flex xs11 >
      <div class="d-flex align-baseline">
      <h2 class="text-dark">{{ title }}</h2>
      <h2 class="ml-2 text-dark" v-if="subtitle">|</h2>
      <h3 class="text-dark ml-2"> {{subtitle}}</h3>
      </div>
    </v-flex>
    <v-flex v-if="useBackBtn" xs1 class="d-flex justify-center">
      <v-btn rounded small @click="goBack()"
             color="#295099">
        <span class="flex center text-white"
          ><v-icon>mdi-arrow-left</v-icon> Back
        </span>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "page-title",
  props: {
    title: String,
    subtitle: String,
    useBackBtn: Boolean,
  },

  components: [],

  methods: {
    goBack() {
      return this.$router.back();
    },
  },
};
</script>

<style>
</style>
